<template>
  <b-select
    ref="select"
    :value="role.value"
    :loading="loading"
    :disabled="loading"
    expanded
    @input="confirmChange"
  >
    <option v-for="option in roles" :key="option.value" :value="option.value">
      {{ option.label }}
    </option>
  </b-select>
</template>

<script>
export default {
  name: "UserRoleSelect",
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      roles: [
        { value: "client", label: "Client" },
        { value: "agent", label: "Agent" },
        { value: "admin", label: "Admin" }
      ]
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"](this.userId);
    },
    role() {
      if (this.user.isAdmin) return this.roles[2];
      if (this.user.isAgent) return this.roles[1];
      return this.roles[0];
    }
  },
  methods: {
    confirmChange(role) {
      const vm = this;
      const confirm = this.$confirm.open({
        parent: this,
        props: {
          autoClose: false,
          action: `Change to ${role}`,
          message: `Are you sure you want to grant '${this.user.email}' ${role} rights?`
        },
        canCancel: ["outside"],
        events: {
          confirmed: async () => {
            await this.updateAccessRights(role);
            confirm.close();
          }
        }
      });
      confirm.$on("close", () => {
        vm.$el.querySelector(
          "select"
        ).options.selectedIndex = this.$_.findIndex(
          vm.roles,
          r => r === vm.role
        );
      });
    },
    updateAccessRights(value) {
      this.loading = true;
      this.$store
        .dispatch("user/updateAccessRights", {
          userId: this.userId,
          role: value
        })
        .then(({ message }) => {
          this.loading = false;
          this.$toast.open({
            message: message,
            position: "is-bottom",
            queue: false
          });
        })
        .catch(error => {
          this.loading = false;
          this.$toast.open({
            message: error.message,
            position: "is-bottom",
            queue: false,
            type: "is-danger"
          });
        });
    }
  }
};
</script>
